// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {atomWithQuery} from "jotai-tanstack-query";
import {useApiAuth} from "services/api/useApiAuth";

interface RequestData {
  year?: number;
  quarter?: string | number;
  date_based_quarter?: string | number;
  market?: string | any;
  uploaded_by?: string;
  compliant?: number;
  upload_process_status_id?: string;
  username?: string;
  matched?: boolean | any;
  search?: string;
  csv_id?: string;
  type?: string;
  facility_id?: any;
  ui_batch_id?: string;
  showLatest?: boolean;
  showAll?: boolean;
  file_id?: string;
  advertiser?: string;
  ae_name?: string;
  path?: string;
}

export const useAtomWithQuery = () => {
  const {makeRequest} = useApiAuth();

  const requestAtom = (requestName: string, requestPath: string, data: RequestData, setErrorState?: any) => {
    if (data?.market === "reject") {
      return atomWithQuery(() => ({
        queryKey: [requestName, params], // Ensure queryKey is unique
        queryFn: async () => {
          try {
            return [];
          } catch (error: any) {
            console.log("error", error);
            if (setErrorState) {
              setErrorState(`Error Getting Data ${error.message}`);
            }
            throw error; // Propagate the error
          }
        },
        // staleTime: 5 * 60 * 1000, // 5 minutes
        // cacheTime: 10 * 60 * 1000, // 10 minutes
        enabled: true,
        refetchOnWindowFocus: false, // Disable refetch on window focus
      }));
    }

    const params: any = {
      year: data?.year ? data.year : undefined,
      quarter: data?.quarter && data.quarter !== "All Quarters" ? data.quarter.toString().replace("Q", "") : undefined,
      date_based_quarter: data?.date_based_quarter && data.date_based_quarter !== "All Quarters" ? data.date_based_quarter.toString().replace("Q", "") : undefined,
      market: data?.market && !data?.market.includes("All Markets") ? data.market : undefined,
      compliant: data?.compliant ? data?.compliant : undefined,
      uploaded_by: data?.uploaded_by ? data.uploaded_by : undefined,
      upload_process_status_id: data?.upload_process_status_id ? data.upload_process_status_id.toString() : undefined,
      username: data?.username ? data.username : undefined,
      matched: data?.matched ? data.matched : undefined,
      search: data?.search ? data.search : undefined,
      csv_id: data?.csv_id ? data.csv_id : undefined,
      type: data?.type ? data.type : undefined,
      facility_id: data?.facility_id ? data.facility_id : undefined,
      ui_batch_id: data?.ui_batch_id ? data.ui_batch_id : undefined,
      showLatest: data?.showLatest ? data.showLatest : undefined,
      showAll: data?.showAll ? data.showAll : undefined,
      advertiser: data?.advertiser ? data.advertiser : undefined,
      ae_name: data?.ae_name ? data.ae_name : undefined,
      path: data?.path ? data.path : undefined
    };
    const errorString = `No documents found ${data?.quarter ? "for " + data.quarter.toString() : data?.date_based_quarter ? "for " + data.date_based_quarter.toString() : ""} ${data?.year ? "in " + data.year.toString() : ""} ${data?.market && data?.market.includes("All") ? "across " + data.market.toString() : data?.market ? "for " + data.market : ""}`;
    return atomWithQuery(() => ({
      queryKey: [requestName, params], // Ensure queryKey is unique
      queryFn: async () => {
        try {
          // urlPaths.ISSUES_QUEUE
          const data = await makeRequest("GET", requestPath, null, params);
          if (data?.items && data?.items?.length <= 0) {
            if (setErrorState) {
              setErrorState(errorString);
            }
          }
          return data;
          // if (data?.summary) {
          //   return data;
          // } else if (data?.items) {
          //   return data.items;
          // } else {
          //   return data;
          // }
        } catch (error: any) {
          console.log("error", error);
          if (setErrorState) {
            setErrorState(`Error Getting Data ${error.message}`);
          }
          throw error; // Propagate the error
        }
      },
      // staleTime: 5 * 60 * 1000, // 5 minutes
      // cacheTime: 10 * 60 * 1000, // 10 minutes
      enabled: true,
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }));
  };

  return {
    requestAtom
  };

};