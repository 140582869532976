import React, {lazy, Suspense, useEffect, useState} from "react";
import FccPoliticalCandidatesAndIssuesForm from "components/ui/forms/political_candidates_and_issues";
import {TableCellModalButtonV2} from "components/ui/table/components/cell/ModalV2";
import {useAtom} from "jotai/index";
import Paper from "@mui/material/Paper";
import {useMatch} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));


export const FccPoliticalUpload = (props: any) => {
  const
    wideOrbitId = "/political-candidates-and-issues/upload/:id",

    match = useMatch(wideOrbitId),
    id = match?.params?.id,
    [submit, setSubmit] = useState(false),
    [data, setData] = useState<any>([]),
    {requestAtom} = useAtomWithQuery(),
    politicalMatches = id ? requestAtom("politicalMatch", `${urlPaths.POLITICAL_MATCH}?csv_id=${id}`, {}) : undefined,
    [politicalMatchesState] = useState<any>(politicalMatches);

  let atomQueryResult: any = null;
  if (id && politicalMatchesState) {
    atomQueryResult = useAtom<AtomQuery>(politicalMatchesState)[0];
  }

  useEffect(() => {
    if (id && atomQueryResult) {
      setData(atomQueryResult?.data || []);
    }
  }, [id, atomQueryResult]);

  const
    buttonBarButons: any = {
      mobile: props.screen,
      left: [],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/upload/"
      }]
    },
    topperProps: any = {
      title: !id || !data ? `Political Document Uploads` : data && data.length > 0 && data[0]?.advertiser ? `Uploads: ${data[0]?.advertiser}` : `Political Document Uploads`,
      inputs: [{
        label: "Upload To FCC",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setSubmit(true);
          setTimeout(() => {
            setSubmit(false);
          }, 5000);
        })
      }]
    };

  return (<Suspense fallback={<></>}>
    <Title {...{
      title: "Political Document Uploads",
    }} />
    <Breadcrumb {...{
      breadcrumb: ["Home", "Political Candidates & Issues"]
    }} />
    <TableCellModalButtonV2 {...{modal: {modalForm: FccPoliticalCandidatesAndIssuesForm}}} />
    <ButtonBar {...buttonBarButons} />
    <Topper {...{...topperProps, mobile: props.mobile}} />
    <Paper
      sx={{
        marginTop: "25px",
        borderRadius: "0px",
        margin: props?.mobile ? "25px 10px" : undefined
      }}
      elevation={props?.mobile ? 3 : 4}>
      <FccPoliticalCandidatesAndIssuesForm {...{
        docs: !data ? [] : data,
        data: data,
        id: id,
        mobile: props?.mobile,
        submit: submit
      }} />
    </Paper>
  </Suspense>);
};