import React, {lazy, Suspense, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FacilityDetails from "components/ui/facilitydetails/index";
import StatusDetails from "pages/status/components/StatusDetails";

const
  Stack = lazy(() => import("@mui/material/Stack")),
  Typography = lazy(() => import("@mui/material/Typography")),
  Box = lazy(() => import("@mui/material/Box"));

export function Title(props: any) {
  const
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),

    Title = styled(Typography)`
      margin: 0px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.334;
      letter-spacing: 0em;
    `,
    Container = styled(Box)`
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      flex-direction: row;
      justify-content: space-between;
      margin-top: ${(isSmallScreen) ? "25px" : "0px"};
      margin-left: ${(isSmallScreen) ? "10px" : "0px"};
    `;

  const [titleComponent, setTitleComponent] = useState("");
  useEffect(() => {
    setTitleComponent("");
    if (props.topLevelData && props.topLevelData.screen === "facility") {
      setTitleComponent("facility");
    } else if (props.topLevelData && props.topLevelData.screen === "status") {
      setTitleComponent("status");
    } else if (props.topLevelData && props.topLevelData.screen === "queue") {
      setTitleComponent("queue");
    } else if (props.topLevelData && props.topLevelData.screen === "document") {
      setTitleComponent("document");
    } else {
      setTitleComponent("general");
    }
  }, [props]);

  return (
    <Suspense fallback={<></>}>
      <Container>
        <Stack spacing={0}>
          {titleComponent === "facility" ? <FacilityDetails {...{...props.topLevelData}} /> : <></>}
          {titleComponent === "status" || titleComponent === "queue" ? <>
            <Title variant={"h5"}>{props?.title} {props?.topLevelData?.document_name}</Title>
            <StatusDetails {...{...props.topLevelData}} />
          </> : <></>}
          {titleComponent === "document" ? <>
            <Title variant={"h5"}>{props?.title} {props?.topLevelData?.document_name}</Title>
          </> : <></>}
          {titleComponent === "general" ? <Title variant={"h5"}>{props?.title}</Title> : <></>}
        </Stack>
      </Container>
    </Suspense>
  );
}