import React, {lazy, Suspense, useEffect} from "react";
import {cachedRequests} from "services/api/cachedRequests";
import {FccPoliticalUploadQueue} from "pages/fcc_political_candidates_and_issues/components/tables/upload_queue";
import FccPoliticalFetchNewFccDocuments from "components/ui/forms/political_fetch_new_fcc_documents";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index"));

export const FccPoliticalQueue = (props: any) => {

  const {cachedMarketsData} = cachedRequests();

  useEffect(() => {
    cachedMarketsData();
  }, []);

  const
    buttonBarButons: any = {
      left: [{
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/queue/"
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues", "Upload Queue"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <FccPoliticalUploadQueue {...{...props, showFccLink: false}} />
    </Suspense>
  );
};