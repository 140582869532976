import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";
import ButtonBar from "components/ui/buttonbar";
import FccPoliticalFetchNewFccDocuments from "components/ui/forms/political_fetch_new_fcc_documents";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Button = lazy(() => import("@mui/material/Button")),
  Box = lazy(() => import("@mui/material/Box")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));
export const FccPoliticalFullReportTable = (props: any) => {
  const
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {getCurrentYear, getArrayOfYears, convertDate} = useDate(),
    {getDocumentQuarter} = useQuarter(),
    [compliant, setCompliance] = useState<any>(false),
    [year, setYear] = useState<any>(getCurrentYear()),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("My Markets"),
    [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true)),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    complianceAtom = requestAtom("complianceCompliance", urlPaths.ISSUES_STATUS_COMPLIANCE, {
      year: year,
      market: props?.facility_id ? undefined : props?.market ? [props.market] : market === "My Markets" ? allUserMarkets : [market], /*market,*/
      facility_id: props?.market ? undefined : props?.facility_id ? props.facility_id : undefined,
      quarter: quarter,
      compliant: 1
    }, setErrorState),
    issuesAtom = requestAtom("complianceIssues", urlPaths.POLITICAL_ISSUES, {
      year: year,
      market: props?.facility_id ? undefined : props?.market ? [props.market] : market === "My Markets" ? allUserMarkets : [market], /*market,*/
      facility_id: props?.market ? undefined : props?.facility_id ? props.facility_id : undefined,
      path: props?.terms ? "Terms and Disclosures" : undefined,
      quarter: quarter,
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [issuesState, setIssuesState] = useState<any>(() => issuesAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(compliant ? complianceState : issuesState);

  useEffect(() => {
    // Reset the quarter dropdown if the user is switching between years so its not a blank select
    if (quarter !== "All Quarters" && Number(getCurrentYear()) <= year && Number(getCurrentQuarter()) < Number(quarter.split("Q")[1])) {
      setQuarter(`Q${getCurrentQuarter()}`);
    }
  }, [year, quarter, market, compliant, allUserMarkets]);


  useEffect(() => {
    if (compliant) {
      // Remove the all quarters select option when viewing compliant documents
      if (quarter === "All Quarters") {
        setQuarter(`Q${getCurrentQuarter()}`);
      }
      setQuartersArray(getArrayOfQuarters(year.toString(), false));
    } else {
      setQuartersArray(getArrayOfQuarters(year.toString(), true));
    }
  }, [year, quarter, market, compliant, allUserMarkets]);


  useEffect(() => {
    if (compliant) {
      return setComplianceState(complianceAtom);
    } else {
      return setIssuesState(issuesAtom);
    }
  }, [year, quarter, market, compliant, isError, allUserMarkets]);

  useEffect(() => {
    cachedMarketsData();
  }, []);


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          fcc_directory: string,
          containing_folder_link: string,
          date_required: string,
          files: {
            file_id: string,
            link: string,
            name: string,
            upload_date: string,
            campaign_name: string,
            quarter: number
          }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={3}>
          <Grid xs sx={{minHeight: "fit-content", height: "auto", display: "inline-block"}}>
            <div style={{lineHeight: "normal"}}><Box
              component="img"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              loading="lazy"
              sx={{
                lineHeight: "normal",
                width: "auto",
                maxHeight: {xs: 50, md: 50},
              }}
              alt={`${params.row.callsign} Logo`}
              src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
            />
            </div>
          </Grid>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span>Market: {params.row.market}</span>
              <span>Callsign: {params.row.callsign}</span>
              <span>Service: {params.row.service}</span>
              <span>Facility ID: {params.row.facility_id}</span>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
              <Stack direction="column" spacing={1}>{params?.row?.files?.map((doc: {
                file_id: string, link: string, name: string, upload_date: string, campaign_name: string, quarter: number
              }) => {
                return (
                  <Button key={uuidv4()} size={"small"} variant="contained"
                    href={`/craft/view/political-candidates-and-issues/document/${doc.link}`}
                    sx={{boxShadow: 0, width: "100%"}}>
                    <Stack direction="row" spacing={2}>
                      <strong style={{
                        fontWeight: "600",
                        maxWidth: "180px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}>{doc.name}</strong>
                      <h5 style={{
                        fontWeight: "100"
                      }}>Q{getDocumentQuarter(doc.upload_date)}</h5>
                      <h5 style={{
                        fontWeight: "100"
                      }}>{convertDate(doc.upload_date)}
                      </h5>
                    </Stack></Button>);
              })} <Button variant="outlined"
                href={params.row.containing_folder_link}
                sx={{boxShadow: 0, width: "100%"}}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target={"_blank"}
                key={uuidv4()}>
                <FolderIcon/>
                <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
              </Button></Stack>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      width: 120,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 80,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 80,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
      renderCell: (params: { row: { service: string } }) => {
        return params.row.service;
      }
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      width: 90,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "fcc_directory",
      id: "fcc_directory",
      headerName: "Directory",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      width: 130,
      renderCell: (params: { row: { containing_folder_link: string } }) => {
        return <Button variant="outlined"
          href={params.row.containing_folder_link}
          sx={{boxShadow: 0, borderColor: "transparent"}}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target={"_blank"}
        >
          <FolderIcon/>
          <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW ON FCC</span>
        </Button>;
      }
    }, {
      field: "candidate_campaign_name",
      id: "candidate_campaign_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: { row: { candidate_campaign_name: string } }) => {
        return (params.row.candidate_campaign_name);
        // return <Link
        //   to={`/political-candidates-and-issues/advertiser/${params?.row?.candidate_campaign_name}`}>{params.row?.candidate_campaign_name}</Link>;
      }
    }, {
      field: "office",
      id: "office",
      headerName: "Office / Folder",
      flex: 1,
      renderCell: (params: { row: { office: string } }) => {
        return (params.row.office);
      }
    }, {
      field: "documents",
      id: "documents",
      headerName: "Pdf Name / Pdf Link",
      flex: 2,
      align: "left",
      cellClassName: "documents",
      renderCell: (params: { row: { files: [] } }) => {
        const data: any = [];
        if (params?.row?.files) {
          const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
          files.map((doc: {
            id: string,
            name: string,
            link: string,
            upload_date: string,
            file_id: string
          }) => {
            if (compliant && `Q${getDocumentQuarter(doc.upload_date)}` === quarter && doc.name.includes(".pdf")) {
              data.push(<Link to={`/political-candidates-and-issues/document/${doc.file_id}`}
                style={{
                  color: "#1976d2",
                }} rel="noreferrer"
              >{doc.name.toString()}</Link>);
            } else if (!compliant && doc.name.includes(".pdf")) {
              data.push(<Link to={`/political-candidates-and-issues/document/${doc.file_id}`}
                style={{
                  color: "#1976d2",
                }} rel="noreferrer"
              >{doc.name.toString()}</Link>);
            } else {
              data.push(<p>{doc.name.toString()}</p>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "matched",
      id: "matched",
      headerName: "Matched",
      width: 80,
      align: "center",
      cellClassName: "matched",
      renderCell: (params: { row: { files: [] } }) => {
        const data: any = [];
        if (params?.row?.files) {
          const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
          files.map((doc: {
            id: string,
            name: string,
            link: string,
            upload_date: string,
            file_id: string,
            matched: boolean,
            matchData: {
              date: string,
              csv_id: string,
              username: string
            }
          }) => {
            if (doc.matched && doc.matchData.csv_id) {
              data.push(<Link to={`/political-candidates-and-issues/upload/${doc.matchData.csv_id}`}
                style={{
                  color: "#1976d2",
                }} rel="noreferrer"
              ><CheckCircleOutlineIcon sx={{color: "green"}}/></Link>);
            } else {
              data.push(<p></p>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "quarter",
      id: "quarter",
      headerName: "Quarter",
      align: "center",
      width: 80,
      cellClassName: "quarter",
      renderCell: (params: { row: { files: [] } }) => {
        const data: any = [];
        if (params?.row?.files) {
          const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
          files.map((doc: {
            id: string,
            name: string,
            link: string,
            upload_date: string,
            file_id: string,
            quarter: number,
            campaign_name: string
          }) => {
            if (compliant && `Q${doc.quarter}` === quarter) {
              data.push(<span>{doc.quarter}</span>);
            } else if (!compliant) {
              data.push(<span>{doc.quarter}</span>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "latest_date",
      headerName: "Upload Date",
      sortable: true,
      flex: 1,
      cellClassName: "latest_date",
      valueGetter: (params: any) => {
        const latestDate = params?.row?.latest_date;
        console.log("valueGetter latest_date:", latestDate, typeof latestDate);
        return latestDate;
      },
      sortComparator: (v1: any, v2: any) => {
        console.log("sortComparator values:", v1, v2);
        const val1 = v1 ?? 0;
        const val2 = v2 ?? 0;
        return val1 - val2;
      },
      // renderCell: (params: any) => {
      //   const latestDate = params?.row?.latest_date;
      //   if (latestDate) {
      //     const dateString = convertDate(new Date(latestDate).toISOString());
      //     return (
      //       <Stack
      //         direction="column"
      //         justifyContent="center"
      //         alignItems="flex-start"
      //         spacing={-2.5}
      //       >
      //         <span>{dateString}</span>
      //       </Stack>
      //     );
      //   } else {
      //     return null;
      //   }
      // },
      renderCell: (params: any) => {
        const data: any = [];
        if (params?.row?.files) {
          const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
          files.forEach((doc) => {
            if (compliant && `Q${getDocumentQuarter(doc.upload_date)}` === quarter) {
              data.push(<span key={uuidv4()}>{convertDate(doc.upload_date)}</span>);
            } else if (!compliant) {
              data.push(<span key={uuidv4()}>{convertDate(doc.upload_date)}</span>);
            }
          });
        }
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={-2.5}
          >
            {data}
          </Stack>
        );
      },
    }
      // {
      //   field: "dates",
      //   id: "dates",
      //   headerName: "Upload Date",
      //   flex: 1,
      //   cellClassName: "dates",
      //   renderCell: (params: any) => {
      //     const data: any = [];
      //     if (params?.row?.files) {
      //       const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
      //       files.map((doc: {
      //         id: string,
      //         name: string,
      //         link: string,
      //         upload_date: string,
      //         file_id: string
      //       }) => {
      //         if (compliant && `Q${getDocumentQuarter(doc.upload_date)}` === quarter) {
      //           data.push(<span key={uuidv4()}>{convertDate(doc.upload_date)}</span>);
      //         } else if (!compliant) {
      //           data.push(<span key={uuidv4()}>{convertDate(doc.upload_date)}</span>);
      //         }
      //       });
      //     }
      //     return <Stack
      //       direction="column"
      //       justifyContent="center"
      //       alignItems="flex-start"
      //       spacing={-2.5}
      //     >
      //       {data}
      //     </Stack>;
      //   },
      // valueGetter: (params: any) => {
      //   // const data: any = [];
      //   console.log("files v2", this);
      //   const files = _.orderBy(params?.files, ["upload_date"], ["desc"]);
      //   // console.log("files v2", files);
      //   // params?.row?.files.map((file: { upload_date: string }) => data.push(brand?.service?.toString()));
      //   // return files[0]?.upload_date?.toString();
      //   return <div
      //     dangerouslySetInnerHTML={{__html: JSON.stringify(files)}}
      //   />;
      // }

      // valueFormatter: (params: any) => params?.row?.files.map((doc: any) => doc.upload_date)
      // valueGetter: (params: any) => {
      //   if (params?.row?.files) {
      //     const files = _.orderBy(params?.row?.files, ["upload_date"], ["desc"]);
      //     console.log("files", files[0].upload_date);
      //     return files[0].upload_date.toString();
      //   }
      //   // params?.row?.files.map((doc: any) => doc.upload_date)
      // }
      // }
    ],
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          data: {
            market: props?.market ? props.market : undefined,
            facility_id: props?.facility_id ? props.facility_id : undefined,
          },
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: []
    },
    topperProps: any = {
      title: props?.title ? props.title : "All Political Candidates & Issues",
      inputs: [{
        label: <span style={{fontSize: "10px"}}><FolderIcon
          sx={{marginRight: "5px", marginTop: "-5px"}}/>VIEW FCC POLITICAL FILES</span>,
        type: "button",
        onClick: (() => {
          window.open(data?.base_folder_link);
        }),
        display: props?.showFccLink && data && data?.base_folder_link ? props.base_folder_link : false
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "150px",
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: quartersArray,
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "My Markets",
        state: market,
        setState: setMarket,
        display: !props?.market && !props?.facility_id ? true : false
      }, {
        label: "Reset",
        type: "button",
        onClick: (() => {
          setCompliance(false);
          setMarket("My Markets");
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      {props.buttonBar ? <ButtonBar {...buttonBarButons} /> : <></>}
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        tableKey: "issuesTable",
        mobile: props.mobile,
        data: data?.items?.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : columns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        sortBy: "dates",
        minHeight: data?.items.length > 0 ? "800px" : undefined
      }} />
    </Suspense>
  );
};